import React from 'react';
import './style/reset.css';
import './style/styles.css';
import logo from './logo.svg';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAboutText: false,
    };

    this.handleLogoClick = this.handleLogoClick.bind(this);
  }

  handleLogoClick() {
    this.setState((prevState) => ({
      showAboutText: !prevState.showAboutText,
    }));
  }

  render() {
    const { showAboutText } = this.state;
    const aboutText = (
      <div className={`about ${showAboutText ? 'visible' : ''}`}>
        <p>Simulcast is driven by <a href="https://tristanfriedbergrodman.com/" target="_blank">Tristan Friedberg Rodman</a> in Los Angeles, California.</p>
      </div>
    );

    const projectsList = (
      <div className={`projects ${!showAboutText ? 'visible' : ''}`}>
        <ul>
          <li><a href="https://minutes-to.com/" target="_blank">Minutes To</a></li>
          <li><a href="http://www.watchingthepoppies.com/" target="_blank">Watching the Poppies</a></li>
          <li><a href="http://losangelesends.com/" target="_blank"><i>Los Angeles Ends</i></a></li>
        </ul>
      </div>
    );

    return (
      <div className="container">
        <div className="logo">
            <img src={logo} alt="Simulcast Logo" onClick={this.handleLogoClick}></img>
        </div>
        {aboutText}
        {projectsList}
      </div>
    );
  }
}

export default App;